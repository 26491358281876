const colors = {
  fa_red: '#eb443e',
  yellow: '#ffd800',
  black: '#000',
  white: '#fff'
}

export default {
  darkBackground: colors.black,
  primaryHighlight: colors.yellow,
  secondaryHighlight: colors.white
}
